import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { WWWeaponEntry } from '../../../modules/ww/weapons/ww-weapon';
import { WWCharacter } from '../../../modules/ww/common/components/ww-character';

const WWGuidesBegEvents: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner events</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_events.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Beginner events</h1>
          <h2>
            List of all beginner events and login calendars in Wuthering Waves
            and all the rewards you can obtain from them!
          </h2>
          <p>
            Last updated: <strong>10/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Pre-register Events" />
        <StaticImage
          src="../../../images/ww/generic/event_prereg.webp"
          alt="Guide"
        />
        <ul>
          <li>
            <strong>20x Lustrous Tide</strong> (standard summon tickets)
          </li>
          <li>
            <strong>80k Shell Credits</strong>
          </li>
          <li>
            <strong>10x Advanced Resonance Potion</strong> (gives EXP to a
            character)
          </li>
          <li>
            <strong>200x Astrite</strong> (premium currency)
          </li>
          <li>
            <strong>1x Sigil: En Route</strong>
          </li>
          <li>
            <strong>1x Ranger's Series Weapon Selector Box</strong>
          </li>
        </ul>
        <h5>Ranger's Series Weapon Selector Box</h5>
        <div className="ww-weapons-container">
          <div>
            <WWWeaponEntry name="Undying Flame" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Jinzhou Keeper" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Amity Accord" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry
              name="Commando of Conviction"
              level={[1]}
              mode="icon"
            />
          </div>
          <div>
            <WWWeaponEntry name="Dauntless Evernight" level={[1]} mode="icon" />
          </div>
        </div>
        <SectionHeader title="Gifts Of Thawing Frost" />
        <StaticImage
          src="../../../images/ww/generic/event_4.webp"
          alt="Guide"
        />
        <p>
          Gifts Of Thawing Frost is a permanent event that simply requires you
          to login to the game to obtain a new reward every day for 7 days in
          total. List of rewards:
        </p>
        <ul>
          <li>
            <strong>4x Lustrous Tide</strong> (standard summon tickets)
          </li>
          <li>
            <strong>4x Radiant Tides</strong> (rate-up summon tickets)
          </li>
          <li>
            <strong>1x Sanhua</strong>
          </li>
        </ul>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="sanhua" enablePopover showLabel />
        </div>
        <SectionHeader title="Awakening Journey" />
        <StaticImage
          src="../../../images/ww/generic/event_3.webp"
          alt="Guide"
        />
        <p>
          Awakening Journey is a permanent event that rewards you for increasing
          your Union Level which basically is your account level. The rewards in
          this event are really good and the faster you will climb in ranks, the
          faster you will be able to obtain them. List of rewards:
        </p>
        <ul>
          <li>
            <strong>Union Level 5:</strong>
          </li>
          <ul>
            <li>
              <strong>5x Lustrous Tide</strong> (standard summon tickets)
            </li>
            <li>
              <strong>200x Astrite</strong> (premium currency)
            </li>
          </ul>
          <li>
            <strong>Union Level 15:</strong>
          </li>
          <ul>
            <li>
              <strong>5x Lustrous Tide</strong> (standard summon tickets)
            </li>
            <li>
              <strong>400x Astrite</strong> (premium currency)
            </li>
          </ul>
          <li>
            <strong>Union Level 25:</strong>
          </li>
          <ul>
            <li>
              <strong>15x Lustrous Tide</strong> (standard summon tickets)
            </li>
            <li>
              <strong>2x Crystal Solvent</strong> (60 Stamina each)
            </li>
          </ul>
          <li>
            <strong>Union Level 35:</strong>
          </li>
          <ul>
            <li>
              <strong>15x Lustrous Tide</strong> (standard summon tickets)
            </li>
            <li>
              <strong>4x Crystal Solvent</strong> (60 Stamina each)
            </li>
          </ul>
          <li>
            <strong>Union Level 45:</strong>
          </li>
          <ul>
            <li>
              <strong>1x Winter Brume Weapon Box</strong>
            </li>
            <li>
              <strong>1000x Astrite</strong> (premium currency)
            </li>
          </ul>
        </ul>
        <h5>Winter Brume Weapon Box</h5>
        <p>Here's the list of the weapons you can obtain from the box:</p>
        <div className="ww-weapons-container">
          <div>
            <WWWeaponEntry name="Emerald of Genesis" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Abyss Surges" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Static Mist" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Cosmic Ripples" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Lustrous Razor" level={[1]} mode="icon" />
          </div>
        </div>
        <SectionHeader title="Rumbling Hollows" />
        <StaticImage
          src="../../../images/ww/generic/event_2.webp"
          alt="Guide"
        />
        <p>
          Rumbling Hollows is a permanent event that simply requires you to
          unlock the Tower of Adversity mode and clear the Experiment Zone
          there. This will provide you with:
        </p>
        <ul>
          <li>
            <strong>80k Shell Credits</strong>
          </li>
          <li>
            <strong>2x Premium Energy Core</strong> (gives EXP to a weapon)
          </li>
          <li>
            <strong>2x Premium Resonance Potion</strong> (gives EXP to a
            character)
          </li>
        </ul>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="yuanwu" enablePopover showLabel />
        </div>
        <SectionHeader title="Drifting Inscription" />
        <StaticImage
          src="../../../images/ww/generic/event_1.webp"
          alt="Guide"
        />
        <p>
          Drifting Inscription is a set of beginner missions that are designed
          to guide the player’s progression at the start of the game. Completing
          these missions will reward you with various resources.
        </p>
        <p>
          There are 6 pages of beginner missions in the Drifting Inscription.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesBegEvents;

export const Head: React.FC = () => (
  <Seo
    title="Beginner events | Wuthering Waves | Prydwen Institute"
    description="List of all beginner events and login calendars in Wuthering Waves and all the rewards you can obtain from them!"
    game="ww"
  />
);
